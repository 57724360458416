/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import { graphql } from 'gatsby'
import Post from './post'

const widgetOptions = {
  filters: true,
  mode: 'collapsed-items',
  items: 15,
}

const PostOperator = props => <Post {...props} widgetOptions={widgetOptions} />

export const pageQueryOperatorPost = graphql`
  query PostOperatorById($id: String!, $locale: String!) {
    post: prismicPostOperator(id: { eq: $id }, lang: { eq: $locale }) {
      id
      uid
      first_publication_date
      last_publication_date
      data {
        title
        description
        keywords
        body {
          ... on PrismicPostOperatorBodyText {
            slice_type
            id
            primary {
              text {
                raw {
                  text
                  type
                }
              }
            }
          }
          ... on PrismicPostOperatorBodyWidget {
            id
            slice_type
          }
          ... on PrismicPostOperatorBodyReviews {
            id
            slice_type
            items {
              author
              date_reviewed
              rating
              review {
                html
                text
              }
            }
          }
          ... on PrismicPostOperatorBodyPreface {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostOperatorBodyImage {
            slice_type
            id
            primary {
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    sizes(maxWidth: 700) {
                      ...GatsbyImageSharpSizes_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    generators: allPrismicGenerator(filter: { uid: { eq: "operator" }, lang: { eq: $locale } }) {
      edges {
        node {
          data {
            formula_h1
            formula_description
            formula
            formula_slug
          }
          uid
        }
      }
    }
  }
`

// PostCountry.query = pageQueryCountry

export default PostOperator
